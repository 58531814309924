import Header from './components/header'
import Body from './components/body/body'
import renderFooter from './components/footer/footer'

function App() {
 

  return (
    <>
    <Header logoSrc={'../src/assets/ApexDev/logo.jpg'} altText={'ApexDev Logo'} />
    <Body />
    {renderFooter()}
    </>
  )
}

export default App
