import React from 'react';
import './header.css';
import  { HeaderProps }  from './types';
import logoImage from '../assets/ApexDev/logo.jpg'; 
import logo from '../assets/ApexDev/ApexDev-02.png'

const Header: React.FC<HeaderProps> = ({ 
  logoSrc = logoImage, altText }) => {
  return (
    <header className="header">
  
        <div>
        <img src={logo} alt={altText} className="Apexlogo" />
        </div>
      
    </header>
  );
};

export default Header;
