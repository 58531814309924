import './footer.css';
import logo from '../footer/assets/ApexDev-02.png'

function renderFooter(): JSX.Element {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div>
        <img src={logo} alt="Company Logo" className="footer-logo" />
        </div>
        
        <div>
        <h3 className="footer-heading">Contact Us</h3>
        <p className="footer-info">(+27) 67 976 2345</p>
        <p className="footer-info"><a href="mailto:lgubevu@gmail.com" className="footer-link">lgubevu@gmail.com</a></p>
        <p className="footer-info">East London, South Africa</p>
        </div>
      </div>
    </footer>
  );
}

export default renderFooter;
