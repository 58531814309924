import React from 'react';
import { BodyProps } from './types';
import bodyImage from './bodyAssets/Banner.jpg';
import graphicDesign from './bodyAssets/logodesign-01.jpg';
import softwareDev from './bodyAssets/software-01.jpg';
import webDesign from './bodyAssets/WebDesign-01.jpg';
import multimedia from './bodyAssets/Multimedia-01.jpg';
import socialMedia from './bodyAssets/Social-Media-01.jpg';
import brand from './bodyAssets/Brand-01-01.jpg'
import './body.css';
import ProjectButton from './buttons/button';
import getXimage from './buttons/assets/Projects-01.jpg';
import ndamaseProperties from '../body/buttons/assets/Projects-02.jpg'
import alliedBusinessSolutions from '../body/buttons/assets/Projects-03.jpg'



const Body: React.FC<BodyProps> = ({ 
    imageSrc = bodyImage, 
    graphicDesignImage = graphicDesign, 
    softwareDevelopImage = softwareDev,
    webDesignImage = webDesign,
    multimediaImage = multimedia,
    socialMediaImage = socialMedia,
    brandingImage = brand,
    altText = 'Hero Banner Image of PC' }) => {
  return (
    <>
      <div className="body-container">
     
        <div className='heading-div'>
        <h2 className="h2">
          Bring Your Vision to Life with ApexDev, Transforming Ideas into Digital Reality
        </h2>
        </div>
        <div className='hero-container'>
        
        <div className='paragraph-div'>
        <p className='p'>
          
          Welcome to ApexDev, your partner in transforming ideas into digital reality. 
          We’re a creative and innovative team dedicated to elevating your online presence 
          through cutting-edge web development, stunning graphic design, engaging digital media, 
          and effective social media management. With over 10 years of experience in the industry, 
          we’re passionate about empowering businesses like yours to succeed in today’s fast-paced 
          digital landscape. Explore our services and let’s bring your vision to life.
        </p>
        </div>
        
        
        </div>
      

      <div className='imageDiv'>
        <img src={imageSrc} alt={altText} className="body-image" />
      </div>

        <div className='whoAreWe-div'>
      <h2 className='h2'>Who Are We?</h2>
      <div className='paragraph-div'>
        <p className="p">
        At ApexDev, we're fueled by a passion for pioneering digital solutions that transform 
        businesses. Our team of expert creatives and technologists, led by a seasoned Web & Graphic 
        Designer, Video Editor, SEO Manager, and Front-End Developer, combines over a decade of 
        experience to drive innovation in the digital landscape. With a robust foundation in graphic 
        design, multimedia, and development, we fuse artistic vision with technical precision to deliver 
        exceptional results for our clients.
         </p>
         </div>
        </div>

        <div className='heading-div'>
          <h2 className='h2'>Our Services</h2>
        </div>

        <div className='image-container'> 

          <div > 
          <img src={graphicDesignImage} alt={altText} className="graphic-image" />
          <h2 className='services-heading'>Graphic Design</h2>
        </div>

        <div>
            <img src={softwareDevelopImage} alt={altText} className="web-design-image" />
            <h2 className='services-heading'>Web Development</h2>
        </div>

        <div>
            <img src={webDesignImage} alt={altText} className="software-dev-image" />
            <h2 className='services-heading'>Web Design</h2>
        </div>

    </div>
    <div className='image-container-two'> 
      <div>
        <img src={multimediaImage} alt={altText} className="multimedia-image" />
        <h2 className='services-heading'>Multimedia</h2>
        </div>
        <div>
        <img src={socialMediaImage} alt={altText} className="social-media-image" />
        <h2 className='services-heading'>Social Media</h2>
        </div>
        <div>
        <img src={brandingImage} alt={altText} className="branding-image" />
        <h2 className='services-heading'>Branding</h2>
        </div>
    </div>

    <div>

    <div className='heading-div'>
          <h2 className='h2'>Projects</h2>
          <h3 className='h3'>Web Design</h3>
    </div>

    <div className='buttons-div'>

        <div className='button'> 
            <ProjectButton
            imageSrc={getXimage}
            altText='GetX Button'
            buttonText='E-Commerce Website'
            link='https://www.getx.co.za'
            />
        </div>

        <div className='button'>
            <ProjectButton
            imageSrc={ndamaseProperties}
            altText='Ndamase Properties Button'
            buttonText='Real Estate Company'
            link='https://www.ndamaseproperties.com'
            />
        </div>

        <div className='button'>
            <ProjectButton
            imageSrc={alliedBusinessSolutions}
            altText='Allied Business Solutions Button'
            buttonText='Information Technology Company'
            link='https://www.allied-biz.co.za'
            />
        </div>    


      </div>

  </div>

</div>

   
    </>
  );
};

export default Body;
